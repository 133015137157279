@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-sans;
  }
  h1 {
    @apply text-lg font-semibold;
  }
}

@layer utilities {
  .ellipses-text {
    @apply inline-block w-[9rem] max-w-max overflow-hidden text-ellipsis whitespace-nowrap;
  }
  .scrollbar::-webkit-scrollbar-track {
    @apply !bg-background-light;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    width: 0.3rem;
  }

  .dropdown-scrollbar-thumb::-webkit-scrollbar-thumb {
    @apply bg-zinc-400;
  }
  .dropdown-scrollbar-track::-webkit-scrollbar-track {
    @apply overflow-hidden rounded-lg bg-background-light;
  }

  .dropdown-scrollbar::-webkit-scrollbar {
    width: 0.3rem;
    @apply dropdown-scrollbar-thumb dropdown-scrollbar-track;
  }

  .wavy_underline_red {
    @apply underline decoration-error-light decoration-wavy underline-offset-4;
  }

  .wavy_underline_slate {
    @apply underline decoration-gray-300 decoration-wavy underline-offset-4;
  }

  .table-scrollbar-thumb::-webkit-scrollbar-thumb {
    @apply bg-primary-main;
  }

  .table-scrollbar::-webkit-scrollbar {
    height: 0.15rem;
    @apply table-scrollbar-thumb;
  }
  .scrollbar-thumb::-webkit-scrollbar-thumb {
    @apply rounded-lg bg-primary-main;
  }
  .scrollbar-track::-webkit-scrollbar-track {
    @apply overflow-hidden rounded-lg bg-background-light;
  }

  .scrollbar-dark {
    @apply scrollbar scrollbar-thumb scrollbar-track;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  min-width: 1250px;
  min-height: 100vh;
}
