.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #359583;
  --rdp-background-color: transparent !important;
  --rdp-accent-color-dark: #359583;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
}
